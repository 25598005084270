













































































































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BSpinner,
  BFormRadio,
} from "bootstrap-vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { EntrepriseMyRessif } from "@/api/models/options/myRessif/entrepriseMyRessif";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { EntrepriseMapping } from "@/api/models/options/myRessif/EntrepriseMapping";

@Component({
  components: {
    BButton,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    BForm,
    BFormGroup,
    BModal,
    BFormRadio
  },
})
export default class MyressifMappingsEntreprise extends Vue {
  listMappings: [] = [];
  listEntrepriseMyRessif: PaginatedList<EntrepriseMyRessif> = new PaginatedList<EntrepriseMyRessif>()
  listLoading = false;
  listLoaded = false;

  model: EntrepriseMapping = {};

  async loadMappings() {
    this.listLoading = true;
    await this.$http.ressifnet.entreprises
      .getEntrepriseMappings(this.$route.params.id)
      .then(
        (response: []) => {
          this.listLoading = false;
          this.listMappings = response;
          this.listLoaded = true;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.listLoaded = true;
        }
      );
  }

  async loadEntrepriseMyRessif(params: any){
    await this.$http.ressifnet.myRessif
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "")
      .then(
        (response: PaginatedList<EntrepriseMyRessif>) => {
          this.listEntrepriseMyRessif = response;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.listLoaded = true;
        }
      );
  }

  async addEntrepriseMapping() {
    await this.$http.ressifnet.entreprises
      .addEntrepriseMapping(this.$route.params.id, this.model)
      .then(
        async (response: string) => {
          this.$bvModal.hide("modal-add-liaison-myressif");
          successAlert.fire({
            title: "Ajout d'une liaison",
            text: "Ajout effectué avec succès",
          });
          await this.loadMappings();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            this.$bvModal.hide("modal-add-liaison-myressif");
            errorAlert.fire({
              title: "Oops",
              text: error.message,
            });
          }
        }
      );
    this.model = {}
  }

  async deleteLiaison(id : string) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer la liaison ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.confirmDelete(id)
          } 
          else {
            return
          }
        })
  }

  async confirmDelete(id : string) {
    await this.$http.ressifnet.entreprises.deleteEntrepriseMapping(this.$route.params.id, id)
      .then(async(response: string) => {
        successAlert.fire({
          title: "Suppression d\'une liaison",
          text: "Suppression effectuée avec succès"
        })
        await this.loadMappings()
      }, (error: any) => {
        if (error.response?.data?.detail && error.response.data.detail.length > 0){
          errorAlert.fire({
            text: error.response.data.detail
          })
        } else {
          errorAlert.fire({
            text: error.message
          })
        }    
    })
  }
}
