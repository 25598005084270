


































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { Site } from '@/api/models/options/entreprises/site';
import { Region } from '@/api/models/options/entreprises/region';
import { Branche } from '@/api/models/options/entreprises/branche';
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts';
import { SiteCreateModel } from '@/api/models/options/entreprises/site';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BForm, BFormInput, BFormGroup, BSpinner, BPagination
} from 'bootstrap-vue'
import { VueAutosuggest }  from 'vue-autosuggest';
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BSpinner,
    VueAutosuggest,
    BPagination,
    SearchableVueSelect,
    DeleteIcon,
    EditIcon
  }
})
export default class SiteEntreprise extends Vue {
  @Prop() sharedEntreprise!: boolean;
  currentPageSites: PaginatedList<Site> | null = null
  listRegions: PaginatedList<Region> = new PaginatedList<Region>()
  listBranches: PaginatedList<Branche> = new PaginatedList<Branche>()

  selectedSite: Site | null = null;
  newSite: SiteCreateModel = {
    libelle: "",
    regionId: null
  }
    
  currentPage: number = 1;
  perPage: number = 10;  
  //listLoading = false

  nomInput: string = ""
  regionInput: string = ""
  brancheInput: string = ""

  timeout: any;


  @Watch('currentPage')
  async onPageChanged() {
    await this.loadListSites();
  }

  @Watch("nomInput")
  @Watch("regionInput")
  @Watch("brancheInput")
  async search() {
    clearTimeout(this.timeout);
    setTimeout(async () => {
      await this.loadListSites();
    }, 3000)
  }

  async created() {
    await this.loadListSites();
  }

  async loadListSites() {
    //this.listLoading = true
    await this.$http.ressifnet.sites
      .paginatedList(
        this.$route.params.id, 
        this.currentPage, 
        this.perPage,
        this.nomInput,
        this.regionInput,
        this.brancheInput)
      .then(
        (response: PaginatedList<Site>) => {
          //this.listLoading = false
          this.currentPageSites = response
        }, (error: any) => {
          errorAlert.fire({
            title: 'Oops',
            text: 'Erreur lors du chargement des sites'
          })
        }
      )
      this.newSite = {
        libelle: "",
      }
  }

  async searchRegions(params: any) {
    if (!params || params?.reset) {
      this.listRegions.totalCount = 0;
      this.listRegions.items = [];
    }
    await this.$http.ressifnet.regions.paginatedList(
      this.$route.params.id, 
      params?.pageNumber ?? 1, 
      params?.pageSize ?? 10, 
      params?.search ?? "", 
    )
      .then(
        (response: PaginatedList<Region>) => {
          this.listRegions.totalCount = response.totalCount
          this.listRegions.items = this.listRegions.items!.concat(response.items.filter(ri => !this.listRegions.items!.some(lsi => lsi.id == ri.id)))
          // this.newSite.regionId = this.listRegions.items.length === 1 ? this.listRegions.items[0].id : undefined;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Oops',
            text: 'Erreur lors du chargement des régions'
          })
        }
      )
  }

  async searchBranches(params: any){
    if (!params || params?.reset) {
      this.listBranches.totalCount = 0;
      this.listBranches.items = [];
    }
    await this.$http.ressifnet.branches.paginatedList(
      this.$route.params.id, 
      params?.pageNumber ?? 1, 
      params?.pageSize ?? 10, 
      params?.search ?? "", 
    )
      .then(
        (response: PaginatedList<Branche>) => {
          this.listBranches.totalCount = response.totalCount
          this.listBranches.items = this.listBranches.items!.concat(response.items.filter(ri => !this.listBranches.items!.some(lsi => lsi.id == ri.id)))
        }, (error: any) => {
          errorAlert.fire({
            title: 'Oops',
            text: 'Erreur lors du chargement des branches'
          })
        }
      )
  }

  async prepareCreateSiteModal() {
    this.listBranches = new PaginatedList<Branche>()
    this.listRegions = new PaginatedList<Region>()
    this.$bvModal.show('modal-add-site')
  }

  async createSite(event: any) {
    event.preventDefault();
    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess) => {
        if (isSuccess) {
          await this.$http.ressifnet.sites.post(this.$route.params.id, this.newSite)
            .then(async (response : string) => {
              this.$bvModal.hide('modal-add-site')
              successAlert.fire({
                title: 'Création d\'un site',
                text: 'Ajout effectué avec succès'
              })
              await this.loadListSites()
              this.$emit('load-secteurs')
            }, (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                })
              }
              else {
                this.$bvModal.hide('modal-add-site')
                errorAlert.fire({
                    title: 'Oops',
                    text: error.message
                })
              }
            })
        }
      })
  }

  async updateSite(site: Site) {
    this.listRegions = new PaginatedList<Region>()
    this.listBranches = new PaginatedList<Branche>()
    this.selectedSite = JSON.parse(JSON.stringify(this.currentPageSites!.items.find(x => x.id == site.id)));
    
    this.listRegions.items.push({
      id: this.selectedSite!.regionId,
      libelle: this.selectedSite!.regionLibelle,
      nbSites: 0,
      nbDossiers: 0
    })
    
    if (this.selectedSite?.brancheLibelle) {
      this.listBranches.items.push({
        id: this.selectedSite!.brancheId!,
        libelle: this.selectedSite!.brancheLibelle,
        nbSites: 0,
        nbDossiers: 0
      })
    }
    else {
      await this.searchBranches(null)
    }

    this.$bvModal.show('modal-put-site');
  }

  async confirmUpdate(event: any) {
    event.preventDefault();
    (this.$refs.updateFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess) => {
        if (isSuccess) {
          if (!this.selectedSite)
            return;

          await this.$http.ressifnet.sites.put(this.$route.params.id, this.selectedSite.id, this.selectedSite)
            .then(async(response: any) => {
              this.$bvModal.hide('modal-put-site');
              successAlert.fire({
                title: 'Modification du site',
                text: 'Modification effectuée avec succès',
              })

              await this.loadListSites()
            }, (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                })
              }
              else {
                this.$bvModal.hide('modal-put-site');
                errorAlert.fire({
                  text: error.message,
                })
              }
            })
        }
      })
    
  }

  async deleteSite(site: Site) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer le site ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.confirmDelete(site.id, site.regionId)
          } 
          else {
            return
          }
        })
  }

  async confirmDelete(id : string, regionId: string) {
    await this.$http.ressifnet.sites.delete(this.$route.params.id, id, regionId)
      .then(async(response: string) => {
        successAlert.fire({
          title: "Suppression du site",
          text: "Suppression effectuée avec succès"
        })
        await this.loadListSites()
      }, (error: any) => {
          if (error.response?.data?.detail && error.response.data.detail.length > 0){
            errorAlert.fire({
            text: error.response.data.detail
            })
          } else {
            errorAlert.fire({
            text: error.message
            })
          }
      })
  }
}
