









































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BPagination
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Tag } from '@/api/models/options/entreprises/tag';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts';
import { TagCreateModel } from '@/api/models/options/entreprises/tag'
import { required, min, max } from '@validations'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
    BFormGroup,
    BPagination,
    BSpinner,
    DeleteIcon,
    EditIcon
  }
})
export default class TagEntreprise extends Vue {
  @Prop() sharedEntreprise!: boolean;

  currentPageTags: PaginatedList<Tag> | null = null
  selectedTag: Tag | null = null;

  currentPage: number = 1;
  perPage: number = 10;
  listLoading = false

  newTag: TagCreateModel = {}

  required = required;
  min = min;
  max = max;
  
  async created() {
    await this.loadListTag();
  }

  async loadListTag() {
    this.listLoading = true
    await this.$http.ressifnet.tags.paginatedList(this.$route.params.id, this.currentPage, this.perPage,'')
      .then(
        (response: PaginatedList<Tag>) => {
          this.listLoading = false
          this.currentPageTags = response
        }, (error: any) => {
          this.listLoading = false
          errorAlert.fire({
            title: 'Erreur lors du chargement des tags'
          })
        }
      )
      this.newTag = {};
  }

  @Watch('currentPage')
    async onPageChanged() {
      await this.loadListTag();
  }

  async deleteTag(id : string) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer le tag ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.confirmDelete(id)
          } 
          else {
            return
          }
        })
  }

  async confirmDelete(id : string) {
    await this.$http.ressifnet.tags.delete(this.$route.params.id, id)
      .then(async(response: string) => {
        successAlert.fire({
          title: "Suppression d\'un tag",
          text: "Suppression effectuée avec succès"
        })
        await this.loadListTag()
      }, (error: any) => {
        if (error.response?.data?.detail && error.response.data.detail.length > 0){
          errorAlert.fire({
            text: error.response.data.detail
          })
        } else {
          errorAlert.fire({
            text: error.message
          })
        }    
  })
}

  async createTag(event: any) {
    event.preventDefault();
    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess) => {
        if (isSuccess) {
          await this.$http.ressifnet.tags.post(this.$route.params.id, this.newTag)
            .then(async (response : string) => {
              this.$bvModal.hide('modal-add-tag')
              successAlert.fire({
                title: 'Création d\'un tag',
                text: 'Ajout effectué avec succès'
              })
              await this.loadListTag()
            }, (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                })
              }
              else {
                this.$bvModal.hide('modal-add-tag')
                errorAlert.fire({
                  title: 'Oops',
                  text: error.message
                })
              }
            })
        }
      })
  }

  async updateTag(id : string) {
    this.selectedTag = JSON.parse(JSON.stringify(this.currentPageTags!.items.find(x => x.id == id)));
    this.$bvModal.show('modal-put-tag');
  }

  async confirmUpdate(event: any) {
    event.preventDefault();
    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess) => {
        if (isSuccess) {
          if(!this.selectedTag)
            return;

          await this.$http.ressifnet.tags.put(this.$route.params.id, this.selectedTag.id, this.selectedTag)
            .then(async(response: any) => {
              this.$bvModal.hide('modal-put-tag');
              successAlert.fire({
                title: 'Modification d\'un tag',
                text: 'Modification effectuée avec succès',
              })

              await this.loadListTag()
            }, (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                })
              }
              else {
                this.$bvModal.hide('modal-put-tag');
                errorAlert.fire({
                  text: error.message,
                })
              }
            });
        }
      })
  }

}
    
