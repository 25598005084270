import { render, staticRenderFns } from "./_Tags.vue?vue&type=template&id=17d3c9cb&"
import script from "./_Tags.vue?vue&type=script&lang=ts&"
export * from "./_Tags.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports