







































import { Component, Vue, Prop } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { BButton, BSpinner, BCardText, BFormCheckbox } from "bootstrap-vue";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Tenant } from "@/api/models/options/tenants/tenant";
import { PaginatedList } from "@/api/models/common/paginatedList";
@Component({
  components: {
    BButton,
    BSpinner,
    BCardText,
    BFormCheckbox
  },
})
export default class TenantsPartages extends Vue {
  listTenants: [] = [];
  listEntrepriseTenants: Tenant[] = []
  listLoading = true;
  @Prop() entreprise!: Entreprise;
  @Prop() sharedEntreprise!: boolean;

  async created () {
    await this.loadTenants()
    await this.loadTenantsEntreprise()
  }

  async loadTenants() {
    this.listLoading = true;
    await this.$http.ressifnet.tenants
      .paginatedList(1, 100)
      .then(
        (response: []) => {
          this.listLoading = false;
          this.listTenants = response;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.listLoading = false;
        }
      );
  }

  async loadTenantsEntreprise() {
    this.listLoading = true
    await this.$http.ressifnet.entreprises
      .getEntrepriseTenants(this.entreprise.id)
      .then(
        (response: PaginatedList<Tenant>) => {
          this.listLoading = false;
          this.listEntrepriseTenants = response.items;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.listLoading = false;
        }
      );
  }

  async createEntrepriseTenant(tenantId: string) {
    this.listLoading = true;
    await this.$http.ressifnet.entreprises
      .createEntrepriseTenant(this.entreprise.id, tenantId)
      .then(
        (response: []) => {
          successAlert.fire({
            title: "Ajout d\'une autorisation à une SSIE",
            text: "Ajout effectué avec succès"
          })
          this.loadTenantsEntreprise()
          this.listLoading = false;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.listLoading = false;
        }
      );
  }

  async deleteEntrepriseTenant(tenantId: string) {
    this.listLoading = true;
    await this.$http.ressifnet.entreprises
      .deleteEntrepriseTenant(this.entreprise.id, tenantId)
      .then(
        (response: []) => {
          successAlert.fire({
            title: "Suppression d\'une autorisation à une SSIE",
            text: "Suppression effectuée avec succès"
          })
          this.loadTenantsEntreprise()
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.listLoading = false;
        }
      );
  }

  async selectTenant(event: any, tenantId: string) {
      if(event) {
      await this.createEntrepriseTenant(tenantId)
    } else {
      this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer l\'autorisation ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.deleteEntrepriseTenant(tenantId)
          } 
          else {
            this.listEntrepriseTenants = []
            await this.loadTenantsEntreprise()
            return
          }
        })
    }
  }

  isTenantPartage(tenantId: string) {
    return this.listEntrepriseTenants.some(t => t.id == tenantId)
  }
}
