






































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { Branche } from '@/api/models/options/entreprises/branche';
import { BrancheCreateModel } from '@/api/models/options/entreprises/branche'
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BPagination
} from 'bootstrap-vue'
import { required, min, max } from '@validations'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BSpinner,
    BButton,
    BFormGroup,
    BPagination,  
    DeleteIcon,
    EditIcon
  }
})
export default class BrancheEntreprise extends Vue {
  @Prop() sharedEntreprise!: boolean;
  currentPageBranches: PaginatedList<Branche> | null = null


  selectedBranche: Branche | null = null;
  newBranche: BrancheCreateModel = {}

  required = required;
  min = min;
  max = max;

  currentPage: number = 1;
  perPage: number = 10;
  listLoading = false

  @Watch('currentPage')
    async onPageChanged() {
      await this.loadListBranche();
  }

  async created() {
    await this.loadListBranche();
  }

  async loadListBranche() {
    this.listLoading = true
    await this.$http.ressifnet.branches.paginatedList(this.$route.params.id, this.currentPage, this.perPage,'')
      .then(
        (response: PaginatedList<Branche>) => {
          this.listLoading = false
          this.currentPageBranches = response
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des branches'
          })
        }
      )
      this.newBranche = {};
  }

  async deleteBranche(id : string) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer la branche ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.confirmDelete(id)
          } 
          else {
            return
          }
        })
  }

  async confirmDelete(id : string) {
    await this.$http.ressifnet.branches.delete(this.$route.params.id, id)
      .then(async(response: string) => {
        successAlert.fire({
          title: "Suppression d\'une branche",
          text: "Suppression effectuée avec succès"
        })

        await this.loadListBranche()
      }, (error: any) => {
          if (error.response?.data?.detail && error.response.data.detail.length > 0){
            errorAlert.fire({
            text: error.response.data.detail
            })
          } else {
            errorAlert.fire({
            text: error.message
            })
          }
      })
  }

  async createBranche(event: any) {
    event.preventDefault();

    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess: boolean) => {
        if (isSuccess) {
          await this.$http.ressifnet.branches.post(this.$route.params.id, this.newBranche)
            .then(async (response : string) => {
              this.$bvModal.hide('modal-add-branche')
              successAlert.fire({
                title: 'Création d\'une branche',
                text: 'Ajout effectué avec succès'
              })
              await this.loadListBranche()
            }, (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                })
              }
              else {
                this.$bvModal.hide('modal-add-branche')
                errorAlert.fire({
                    title: 'Oops',
                    text: error.message
                })
              }
            })
        }
      })
  }

  async updateBranche(id : string) {
    this.selectedBranche = JSON.parse(JSON.stringify(this.currentPageBranches!.items.find(x => x.id == id)));
    this.$bvModal.show('modal-put-branche');
  }

  async confirmUpdate(event: any) {
    event.preventDefault();

    (this.$refs.updateFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess: boolean) => {
        if (isSuccess) {
          if(!this.selectedBranche)
            return;

          await this.$http.ressifnet.branches.put(this.$route.params.id, this.selectedBranche.id, this.selectedBranche)
            .then(async(response: any) => {
              this.$bvModal.hide('modal-put-branche');
              successAlert.fire({
                title: 'Modification d\'une branche',
                text: 'Modification effectuée avec succès',
              })

              await this.loadListBranche()
            }, (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                })
              }
              else {
                this.$bvModal.hide('modal-put-branche');
                errorAlert.fire({
                  text: error.message,
                })
              }
            })
        }
      })
  }
}
