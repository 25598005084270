







































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  Secteur,
  SecteurCreateModel,
} from "@/api/models/options/entreprises/secteur";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BSpinner,
  BPagination,
} from "bootstrap-vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Site } from "@/api/models/options/entreprises/site";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    SearchableVueSelect,
    BPagination,
    BSpinner,
    DeleteIcon,
    EditIcon
  },
})
export default class SecteurEntreprise extends Vue {
  @Prop() sharedEntreprise!: boolean;
  currentPageSecteurs: PaginatedList<Secteur> | null = null
  currentPageSites: PaginatedList<Site> = new PaginatedList<Site>()

  currentPage: number = 1;
  perPage: number = 10;
  //listLoading = false

  selectedSecteur: Secteur | null = null;
  newSecteur: SecteurCreateModel = {
    libelle: "",
    siteId: null,
  };

  nomInput: string = "";
  siteInput: string = "";

  timeout: any;

  async created() {
    await this.loadListSecteur();
  }

  @Watch("currentPage")
  async onPageChanged() {
    await this.loadListSecteur();
  }

  @Watch("nomInput")
  @Watch("siteInput")
  async search() {
    clearTimeout(this.timeout);
    setTimeout(async () => {
      await this.loadListSecteur();
    }, 3000)
  }

  async loadListSecteur() {
    //this.listLoading = true
    await this.$http.ressifnet.secteurs
      .paginatedList(
        this.$route.params.id, 
        this.currentPage, 
        this.perPage, 
        this.nomInput, 
        this.siteInput)
      .then(
        (response: PaginatedList<Secteur>) => {
          //this.listLoading = false
          this.currentPageSecteurs = response
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des secteurs",
          });
        }
      );
  }

  async prepareCreateSecteurModal(){
    this.currentPageSites = new PaginatedList<Site>()
    this.$bvModal.show('modal-add-secteur')
  }

  async searchSites(params: any){
    if (!params || params?.reset) {
      this.currentPageSites.totalCount = 0;
      this.currentPageSites.items = [];
    }
    await this.$http.ressifnet.sites.paginatedList(
      this.$route.params.id, 
      params?.pageNumber ?? 1, 
      params?.pageSize ?? 10, 
      params?.search ?? "", 
    )
      .then(
        (response: PaginatedList<Site>) => {
          this.currentPageSites.totalCount = response.totalCount
          this.currentPageSites.items = this.currentPageSites.items!.concat(response.items.filter(ri => !this.currentPageSites.items!.some(lsi => lsi.id == ri.id)))
          // this.newSecteur.siteId = this.currentPageSites.items.length === 1 ? this.currentPageSites.items[0].id : null;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Oops',
            text: 'Erreur lors du chargement des sites'
          })
        }
      )
  }

  async createSecteur(event: any) {
    event.preventDefault();
    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess) => {
        if (isSuccess) {
          await this.$http.ressifnet.secteurs
            .post(this.$route.params.id, this.newSecteur)
            .then(
              async (response: string) => {
                this.$bvModal.hide("modal-add-secteur");
                successAlert.fire({
                  title: "Création d'un secteur",
                  text: "Ajout effectué avec succès",
                });
                await this.loadListSecteur();
                this.newSecteur = {
                  libelle: "",
                  siteId: null
                }
              },
              (error: any) => {
                if (error.response.data.errors) {
                  errorAlert.fire({
                    text: error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ],
                  });
                } else {
                  this.$bvModal.hide("modal-add-secteur");
                  errorAlert.fire({
                    title: "Oops",
                    text: error.message,
                  });
                }
              }
            );
        }
      });
  }

  async updateSecteur(secteur: Secteur) {
    this.selectedSecteur = JSON.parse(
      JSON.stringify(
        this.currentPageSecteurs!.items.find((x) => x.id == secteur.id)
      )
    );
    this.$bvModal.show("modal-put-secteur");
  }

  async confirmUpdate() {
    if (!this.selectedSecteur) return;

    await this.$http.ressifnet.secteurs
      .put(this.$route.params.id, this.selectedSecteur.id, this.selectedSecteur)
      .then(
        async (response: any) => {
          this.$bvModal.hide("modal-put-secteur");
          successAlert.fire({
            title: "Modification du secteur",
            text: "Modification effectuée avec succès",
          });

          await this.loadListSecteur();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            this.$bvModal.hide("modal-put-secteur");
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async deleteSecteur(secteur: Secteur) {
    this.selectedSecteur = JSON.parse(
      JSON.stringify(
        this.currentPageSecteurs!.items.find((x) => x.id == secteur.id)
      )
    );
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer le secteur ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value && this.selectedSecteur) {
          await this.confirmDelete(
            secteur.id,
            this.selectedSecteur.regionId,
            secteur.siteId
          );
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string, regionId: string, siteId: string) {
    await this.$http.ressifnet.secteurs
      .delete(this.$route.params.id, id, regionId, siteId)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression du secteur",
            text: "Suppression effectuée avec succès",
          });
          await this.loadListSecteur();
        },
        (error: any) => {
          if (error.response?.data?.detail && error.response.data.detail.length > 0){
            errorAlert.fire({
              text: error.response.data.detail
            })
          } else {
            errorAlert.fire({
              text: error.message
            })
          } 
        }
      );
  }
}
