




















import { Component, Vue } from 'vue-property-decorator';
import TagEntreprise from './_Tags.vue';
import RegionEntreprise from './_Regions.vue';
import BrancheEntreprise from './_Branches.vue';
import SiteEntreprise from './_Sites.vue';
import SecteurEntreprise from './_Secteurs.vue';
import MyressifMappingsEntreprise from './_MyRessifMappings.vue';
import EntrepriseInfo from './_Entreprise.vue';
import { Entreprise } from '@/api/models/options/entreprises/entreprise';
import TenantsPartages from './_TenantsPartages.vue';

@Component({
  components: {
    TagEntreprise,
    RegionEntreprise,
    BrancheEntreprise,
    SiteEntreprise,
    SecteurEntreprise,
    MyressifMappingsEntreprise,
    TenantsPartages,
    EntrepriseInfo,
  }
})
export default class DetailsEntreprise extends Vue {

  entreprise: Entreprise = {
    id: this.$route.params.id,
    nom: ""
  };

  async created() {
    await this.getEntrepriseById()
  }

  async getEntrepriseById(){
    await this.$http.ressifnet.entreprises
      .getById(this.$route.params.id)
      .then((entreprise : any) => {
        this.entreprise = entreprise
      });
  }

  loadSecteurs() {
    (this.$refs['secteurs'] as any).loadListSecteur()
  }
}
